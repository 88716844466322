import React, { useState } from "react"
import LazyLoad from "react-lazyload"
import {
  Modal,
  Button,
  Icon,
  handleErrorWithPrismic,
  SENTRY_ACTIONS,
  pushTracking,
  EVENT,
  PARAMS,
} from "@lesmills/gatsby-theme-common"
import { API, graphqlOperation } from "aws-amplify"
import { StopChallenge } from "../../graphql/challenges"
import type { LabelType } from "@lesmills/gatsby-theme-common"
import type { ChallengeType } from "../../../../types/ChallengesPageType"

type Props = {|
  handleDismiss: () => void | Promise<void>,
  callback: () => void | Promise<void>,
  challenge: ChallengeType,
  data: {
    stop_challenge_success_message: Array<LabelType>,
    stop_challenge_success_title: Array<LabelType>,
    stop_challenge_title: Array<LabelType>,
    stop_challenge_message: Array<LabelType>,
    cancel_button_label: Array<LabelType>,
    stop_challenge_label: Array<LabelType>,
    close_button_label: Array<LabelType>,
    lmod_challenges_leave_challenge_unhandled_error: Array<LabelType>,
  },
  activeChallenge: ActiveChallengeType,
|}

const StopChallengeModal = ({
  challenge = {},
  data = {},
  handleDismiss = () => {},
  callback = () => {},
}: Props) => {
  const [isProcessing, setProcessing] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState("")
  const {
    challenge_image = {
      alt: "",
      url: "",
      Mobile: {},
      Tablet: {},
    },
    challenge_name = { text: "" },
    challenge_id,
    challenge_intensity = { text: "" },
  } = challenge

  const {
    stop_challenge_success_message = { text: "" },
    stop_challenge_success_title = { text: "" },
    stop_challenge_title = { text: "" },
    stop_challenge_message = { text: "" },
    cancel_button_label = {},
    stop_challenge_label = {},
    close_button_label = {},
    lmod_challenges_leave_challenge_unhandled_error = {},
  } = data

  const handleClick = async () => {
    setProcessing(true)

    try {
      const response = await API.graphql(
        graphqlOperation(StopChallenge, {
          challengeId: challenge_id.text,
        })
      )

      const { errors } = response
      const responseData = response.data || {}
      if (errors && errors.length > 0) {
        handleErrorWithPrismic(
          errors || [],
          lmod_challenges_leave_challenge_unhandled_error.text,
          ({ message }) => setError(message),
          data,
          SENTRY_ACTIONS.STOP_CHALLENGE
        )
        return
      }

      if (responseData.leaveChallenge) {
        setSuccess(true)
        setError("")
        pushTracking({
          event: EVENT.CHALLENGE_END,
          [PARAMS.CHALLENGE_NAME]: challenge_name?.text,
          [PARAMS.CHALLENGE_LEVEL]: challenge_intensity?.text,
        })

        // Reset active challenge
        callback(null)
      }
    } catch (exceptionError) {
      handleErrorWithPrismic(
        exceptionError.errors || [],
        lmod_challenges_leave_challenge_unhandled_error.text,
        ({ message }) => setError(message),
        data,
        SENTRY_ACTIONS.STOP_CHALLENGE
      )
    } finally {
      setProcessing(false)
    }
  }

  return (
    <Modal
      handleDismiss={handleDismiss}
      classNames={{
        content:
          " challenge-popup w-auto md:w-challenge-popup-md sm:w-challenge-popup sm:h-challenge-popup-sm overflow-auto md:py-0 md:px-0",
      }}
      testId="stop-challenge-modal"
    >
      <div
        className={`challenge-popup-content md:h-full flex justify-between items-center my-0 mx-0`}
      >
        {/* Thumbnail */}
        <LazyLoad height="h-challenge-popup-md">
          <img
            alt={challenge_image.alt}
            src={challenge_image.url}
            className="hidden md:h-full md:block object-cover w-challenge-image"
            data-cy="challenge-image"
            data-testid="challenge-image"
          />
        </LazyLoad>

        {/* Content */}
        <div className="flex flex-col justify-center w-full px-24 pt-80 md:pt-0">
          {success && (
            <div className="mx-auto rounded-full mb-25 flex justify-center items-center w-challenge-success-icon h-challenge-success-icon bg-green-600">
              <Icon icon="check-mark" type="base" />
            </div>
          )}
          <h5
            className={`text-6xl md:text-9xl leading-32 uppercase font-primary text-gray-800 uppercase ${
              success ? "text-center" : ""
            }`}
          >
            {success
              ? stop_challenge_success_title.text
              : stop_challenge_title.text}
          </h5>
          <p
            className={`text-gray-800 font-base-light text-base leading-24 my-25 ${
              success ? "text-center" : ""
            }`}
          >
            {(success
              ? stop_challenge_success_message
              : stop_challenge_message
            ).text.replace("@challenge_name", challenge_name.text)}
          </p>

          {!success && (
            <Button
              handleOnClick={handleClick}
              disabled={isProcessing}
              submitting={isProcessing}
              className={`btn btn-primary-red pt-3 pb-3 mb-15 mt-25 w-full`}
              testId="stop-package-btn"
            >
              {stop_challenge_label.text}
            </Button>
          )}
          <Button
            handleOnClick={handleDismiss}
            disabled={isProcessing}
            submitting={false}
            className={`btn pt-3 pb-3 mb-15 w-full ${
              success ? "btn-primary-gray" : "btn-layout"
            }`}
            testId="cancel-package-btn"
          >
            {success ? close_button_label.text : cancel_button_label.text}
          </Button>
          {error && (
            <p className="text-red-600 font-base-light text-base leading-24 mt-25">
              {error}
            </p>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default StopChallengeModal
